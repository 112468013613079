/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import { Link } from 'gatsby';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Components
import Container from '../../components/Container';
import Image from '../../components/Image';
import {
  BodyTextBig,
  FlexWrap,
  TextWrap,
  ImageWrap,
  SectionTitle,
  GraySection,
  SectionLabel,
} from '../../components/Global';
import constants from '../../constants';

const NotesSection = () => (
  <GraySection
    id={constants.DOM_ID.NOTES}
    textAlign="left"
  >
    <Container>
      <FlexWrap>
        <TextWrap width="45%" className="mobile-text-center">
          <SectionLabel mt="40px">Notes</SectionLabel>
          <SectionTitle mb="16px">
            Create documents online
          </SectionTitle>
          <BodyTextBig mb="16px">
            Write, edit and collaborate simultaneously with
            your team members, neatly tracked in
            a Space to keep everything in one place.
          </BodyTextBig>
          <BodyTextBig>
            <Link to="/help/articles/61000165245/">
              Learn more about Notes
            </Link>
          </BodyTextBig>
        </TextWrap>
        <ImageWrap>
          <Image name="product_docs.png" alt="product docs" />
        </ImageWrap>
      </FlexWrap>
    </Container>
  </GraySection>
);

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
NotesSection.propTypes = {
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default NotesSection;
