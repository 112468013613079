/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
import Container from '../../components/Container';
import Image from '../../components/Image';
import {
  BodyTextBig,
  FlexWrap,
  TextWrap,
  ImageWrap,
  SectionTitle,
  SectionLabel,
  WhiteSection,
  GraySection,
} from '../../components/Global';
import constants from '../../constants';

const ExtensionsSection = ({
  backgroundGray,
}) => {
  const content = (
    <Container>
      <FlexWrap alignItems="center">
        <TextWrap width="45%" className="mobile-text-center">
          <SectionLabel>Extensions</SectionLabel>
          <SectionTitle mb="16px">
            Everything you need, nothing you don’t
          </SectionTitle>
          <BodyTextBig mb="16px">
            Choose what you want added to your projects and
            leave everything else.
            &nbsp;
            <br />
            <br />
            Create tasks through email, track time spent on a task or
            share documents through Google Drive.
            Workast can be customized to your needs.
          </BodyTextBig>
          <BodyTextBig>
            <Link to="/help/categories/61000063757/">
              See all extensions
            </Link>
          </BodyTextBig>
        </TextWrap>
        <ImageWrap maxWidth="469px">
          <Image name="product_extensions.png" alt="extensions" />
        </ImageWrap>
      </FlexWrap>
    </Container>
  );

  if (backgroundGray) {
    return (
      <GraySection
        id={constants.DOM_ID.EXTENSIONS}
        textAlign="left"
      >
        {content}
      </GraySection>
    );
  }

  return (
    <WhiteSection
      id={constants.DOM_ID.EXTENSIONS}
      textAlign="left"
    >
      {content}
    </WhiteSection>
  );
};

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
ExtensionsSection.defaultProps = {
  backgroundGray: false,
};
ExtensionsSection.propTypes = {
  backgroundGray: PropTypes.bool,
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default ExtensionsSection;
