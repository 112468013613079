import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from '../components/Container';
import Image from '../components/Image';
import SignUpModal from '../components/SignUpModal';
import {
  BodyTextBig,
  FlexWrap,
  HeroHeading,
  ImageWrap,
  SectionLabel,
  SectionTitle,
  TextWrap,
  WhiteSection,
} from '../components/Global';
import MediaBreakDown from '../styles/MediaBreakDown';
import FullWidthDots from '../components/Footer/full-width-dots.svg';
import SpacesSection from './sections/spaces';
import HomeSection from './sections/home';
import BoardSection from './sections/board';
import CalendarSection from './sections/calendar';
import TasksSection from './sections/tasks';
import ReportsSection from './sections/reports';
import NotesSection from './sections/notes';
import FormsSection from './sections/forms';
import MeetingsSection from './sections/meetings';
import TemplatesSection from './sections/templates';
import ExtensionsSection from './sections/extensions';
import AllFeaturesSection from './sections/all-features';
import TimelineSection from './sections/timeline';
import CustomFieldsSection from './sections/custom-fields';
import SimplifyTeamWork from '../components/SimplifyTeamWork';

const Hero = styled.div`
  padding: 80px 0;
  text-align: center;
  p {
    max-width: 500px;
    width: 100%;
    margin: auto;
  }
  ${MediaBreakDown.lg`
    padding: 50px 0;
  `}
`;

const ProductPage = ({ location }) => (
  <Layout location={location}>
    <SEO
      title="Our Product | Workast | Work Management Platform - Get More Done!"
      description="Workast is a work management platform to create tasks, manage projects, and monitor daily work and productivity. Get more work done with Workast."
      imageName="product_hero.png"
    />
    <Hero>
      <Container>
        <HeroHeading mb="16px">
          Where work gets done
        </HeroHeading>
        <BodyTextBig>
          No matter what your team is working on,
          Workast helps you organize your work,
          hit deadlines and accomplish more.
        </BodyTextBig>
        <Image
          name="product_hero.png"
          alt="product hero"
          maxWidth="950px"
          margin="48px auto 58px"
          borderRadius="10px"
        />
        <SignUpModal buttontext="Try it for free" />
      </Container>
    </Hero>
    <div className="full-width-dots">
      <img src={FullWidthDots} alt="hero dots" />
    </div>
    <SpacesSection />
    <CustomFieldsSection />
    <BoardSection />
    <CalendarSection />
    <TimelineSection />
    <TasksSection
      showCta={false}
    />
    <WhiteSection
      textAlign="left"
    >
      <Container>
        <FlexWrap>
          <TextWrap width="45%" className="mobile-text-center">
            <SectionLabel mt="40px">Slack</SectionLabel>
            <SectionTitle mb="16px">
              Organization where you need it
            </SectionTitle>
            <BodyTextBig mb="16px">
              Create tasks in a Slack channel to capture work
              when you’re having your conversations. Get reminders,
              task updates and notifications through Slack and
              keep your inbox clear.
            </BodyTextBig>
            <BodyTextBig>
              <Link to="/help/articles/61000276127/">
                Learn more about Workast in Slack
              </Link>
            </BodyTextBig>
          </TextWrap>
          <ImageWrap>
            <Image name="product_slack.png" alt="product slack" />
          </ImageWrap>
        </FlexWrap>
      </Container>
    </WhiteSection>
    <div className="full-width-dots">
      <img src={FullWidthDots} alt="hero dots" />
    </div>
    <WhiteSection className="centered-text">
      <Container>
        <SectionTitle mb="16px">
          Make teamwork a breeze
        </SectionTitle>
        <BodyTextBig maxWidth="620px" mb="35px">
          Set up projects, create tasks, set due dates and
          assign work right from your conversation.
        </BodyTextBig>
        <SignUpModal buttontext="Get Started" />
      </Container>
    </WhiteSection>
    <HomeSection
      showCta={false}
    />
    <ReportsSection />
    <NotesSection />
    <FormsSection />
    <MeetingsSection />
    <TemplatesSection />
    <ExtensionsSection
      backgroundGray
    />
    <AllFeaturesSection />
    
    <SimplifyTeamWork />
  </Layout>
);

ProductPage.propTypes = {
  location: PropTypes.shape({
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
  }).isRequired,
};

export default ProductPage;
