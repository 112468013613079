/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import { Link } from 'gatsby';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Components
import Container from '../../components/Container';
import Image from '../../components/Image';
import {
  BodyTextBig,
  FlexWrap,
  TextWrap,
  ImageWrap,
  SectionTitle,
  WhiteSection,
  SectionLabel,
} from '../../components/Global';
import constants from '../../constants';

const SpacesSection = () => (
  <WhiteSection
    id={constants.DOM_ID.SPACES}
    textAlign="left"
  >
    <Container>
      <FlexWrap direction="column-reverse">
        <ImageWrap>
          <Image name="product_spaces.png" alt="product spaces" />
        </ImageWrap>
        <TextWrap width="45%" className="mobile-text-center">
          <SectionLabel>Spaces</SectionLabel>
          <SectionTitle mb="16px">
            Where your work lives
          </SectionTitle>
          <BodyTextBig mb="16px">
            Create a project, event,
            department or team and control who can join the Space.
            Connect a Space to a Slack channel for greater visibility
            when you need it.
          </BodyTextBig>
          <BodyTextBig>
            <Link to="/help/articles/61000165199/">Learn more about Spaces</Link>
          </BodyTextBig>
        </TextWrap>
      </FlexWrap>
    </Container>
  </WhiteSection>
);

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
SpacesSection.propTypes = {
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default SpacesSection;
