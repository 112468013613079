/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Constants
import constants from '../../constants';
// Components
import Container from '../../components/Container';
import Image from '../../components/Image';
import CallToAction from '../../components/CallToAction';
import {
  BodyTextBig,
  SectionTitle,
  GraySection,
  SectionLabel,
  SectionWrapper,
} from '../../components/Global';

const TasksSection = ({
  showCta,
  CtaLink,
  CtaText,
}) => (
  <GraySection
    id={constants.DOM_ID.TASKS}
    className="centered-text"
  >
    <Container>
      <SectionLabel>Tasks</SectionLabel>
      <SectionTitle mb="15px">
        Capture what’s important
      </SectionTitle>
      <BodyTextBig maxWidth="615px">
        Create tasks and subtasks, assign them to team members,
        set deadlines, recurring due dates, add files,
        comments and keep everyone insync of what needs to be done.
      </BodyTextBig>
      <BodyTextBig mb="34px">
        <Link to="/help/articles/61000130112/">
          Learn more about Tasks
        </Link>
      </BodyTextBig>
      <Image
        name="task-details-v2.png"
        alt="product tasks"
        maxWidth="924px"
        borderRadius="10px"
      />
      {showCta && (
        <SectionWrapper>
          <CallToAction
            text={CtaText}
            link={CtaLink}
          />
        </SectionWrapper>
      )}
    </Container>
  </GraySection>
);

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
TasksSection.defaultProps = {
  showCta: true,
  CtaLink: constants.TRIAL.LINK_URL,
  CtaText: constants.TRIAL.LINK_TEXT,
};
TasksSection.propTypes = {
  showCta: PropTypes.bool,
  CtaLink: PropTypes.string,
  CtaText: PropTypes.string,
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default TasksSection;
