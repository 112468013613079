/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import { Link } from 'gatsby';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Components
import Container from '../../components/Container';
import Image from '../../components/Image';
import {
  BodyTextBig,
  FlexWrap,
  TextWrap,
  ImageWrap,
  SectionTitle,
  WhiteSection,
  SectionLabel,
  DotWrap
} from '../../components/Global';
import FullWidthDots from '../../components/Footer/full-width-dots.svg';
import constants from '../../constants';

const FormsSection = () => (
  <WhiteSection
    id={constants.DOM_ID.FORMS}
    textAlign="left"
  >
    <Container>
      <FlexWrap direction="column-reverse">
        <ImageWrap>
          <Image name="product_form.png" alt="product form" />
        </ImageWrap>
        <TextWrap width="45%" className="mobile-text-center">
          <SectionLabel mt="40px">Forms</SectionLabel>
          <SectionTitle mb="16px">
            Streamline processes
          </SectionTitle>
          <BodyTextBig mb="16px">
            Ask questions, conduct surveys and collect data easily
            with customized public and private forms.
            Form responses are turned into Workast tasks in real time.
          </BodyTextBig>
          <BodyTextBig>
            <Link to="/help/articles/61000165244/">
              Learn more about Forms
            </Link>
          </BodyTextBig>
          <DotWrap position="relative" top="20px">
            <div className="full-width-dots">
              <img src={FullWidthDots} alt="hero dots" />
            </div>
          </DotWrap>
        </TextWrap>
      </FlexWrap>
    </Container>
  </WhiteSection>
);

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
FormsSection.propTypes = {
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default FormsSection;
