/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Components
import Container from '../../components/Container';
import Image from '../../components/Image';
import CallToAction from '../../components/CallToAction';
import SignUpModal from '../../components/SignUpModal';
import {
  BodyTextBig,
  SectionTitle,
  GraySection,
  SectionLabel,
} from '../../components/Global';
import constants from '../../constants';


const MeetingsSection = ({
  CtaLink,
  CtaText,
}) => (
  <GraySection
    id={constants.DOM_ID.MEETINGS}
    className="centered-text"
  >
    <Container>
      <SectionLabel>Meetings</SectionLabel>
      <SectionTitle mb="15px">
        The simple way to run meetings
      </SectionTitle>
      <BodyTextBig maxWidth="615px">
        Use Workast to send out the meeting agenda to all attendees,
        write meeting minutes, create tasks and keep track of
        everything discussed in the same document at the same time.
      </BodyTextBig>
      <BodyTextBig mb="34px">
        <Link to="/help/articles/61000282597/">
          Learn more about Meetings
        </Link>
      </BodyTextBig>
      <Image
        name="product_meetings.png"
        alt="meetings"
        maxWidth="1059px"
        borderRadius="10px"
        margin="0 auto 50px"
      />
      {(CtaLink && CtaText)
        ? (
          <CallToAction
            text={CtaText}
            link={CtaLink}
          />
        )
        : (<SignUpModal buttonText="Get Started" />)
      }
    </Container>
  </GraySection>
);

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
MeetingsSection.defaultProps = {
  CtaLink: '',
  CtaText: '',
};
MeetingsSection.propTypes = {
  CtaLink: PropTypes.string,
  CtaText: PropTypes.string,
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default MeetingsSection;
