/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import { Link } from 'gatsby';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Constants
import constants from '../../constants';
// Components
import Container from '../../components/Container';
import Image from '../../components/Image';
import {
  BodyTextBig,
  SectionTitle,
  GraySection,
  SectionLabel,
} from '../../components/Global';

const CustomFieldsSection = () => (
  <GraySection
    id={constants.DOM_ID.CUSTOM_FIELDS}
    className="centered-text"
  >
    <Container>
      <SectionLabel>LIST</SectionLabel>
      <SectionTitle mb="15px">
        From spreadsheet to tasks
      </SectionTitle>
      <BodyTextBig maxWidth="615px">
        With Workast you can capture any kind of task. Create your own
        custom fields to fit your workflow and make sure everyone is on the same page.
      </BodyTextBig>
      <BodyTextBig mb="34px">
        <Link to="/help/articles/61000299035/">Learn more about Custom Fields</Link>
      </BodyTextBig>
      <Image
        name="custom-columns.png"
        alt="product custom columns"
        maxWidth="920px"
        borderRadius="10px"
      />
    </Container>
  </GraySection>
);

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
CustomFieldsSection.propTypes = {};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default CustomFieldsSection;
