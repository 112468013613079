/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import { Link } from 'gatsby';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Components
import Container from '../../components/Container';
import Image from '../../components/Image';
import {
  BodyTextBig,
  FlexWrap,
  TextWrap,
  ImageWrap,
  SectionTitle,
  WhiteSection,
  SectionLabel,
} from '../../components/Global';
import constants from '../../constants';

const TimelineSection = () => (
  <WhiteSection
    id={constants.DOM_ID.BOARD}
    textAlign="left"
  >
    <Container>
      <FlexWrap>
        <TextWrap width="45%" className="mobile-text-center">
          <SectionLabel mt="40px">Timeline</SectionLabel>
          <SectionTitle mb="16px">
            Plan & Execute
          </SectionTitle>
          <BodyTextBig mb="16px">
            Get a full picture of how all the tasks in
            your project fit together to help you hit your deadlines.
          </BodyTextBig>
          <BodyTextBig>
            <Link to="/help/articles/61000301620/">Learn more about Timeline</Link>
          </BodyTextBig>
        </TextWrap>
        <ImageWrap>
          <Image name="timeline-plan.png" alt="product timeline" />
        </ImageWrap>
      </FlexWrap>
    </Container>
  </WhiteSection>
);

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
TimelineSection.propTypes = {
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default TimelineSection;
