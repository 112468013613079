/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Constants
import constants from '../../constants';
// Components
import Container from '../../components/Container';
import Image from '../../components/Image';
import CallToAction from '../../components/CallToAction';
import {
  BodyTextBig,
  SectionTitle,
  GraySection,
  SectionLabel,
  SectionWrapper,
} from '../../components/Global';

const HomeSection = ({
  showCta,
  CtaLink,
  CtaText,
}) => (
  <GraySection
    id={constants.DOM_ID.HOME}
    className="centered-text"
  >
    <Container>
      <SectionLabel>Home</SectionLabel>
      <SectionTitle mb="15px">
        Plan your day
      </SectionTitle>
      <BodyTextBig maxWidth="615px">
        Workast makes it super easy to plan out your day,
        run your meetings and keep track of important tasks.
      </BodyTextBig>
      <BodyTextBig mb="34px">
        <Link to="/help/articles/61000287910/">Learn more about Home</Link>
      </BodyTextBig>
      <Image
        name="product_home.png"
        alt="product home"
        maxWidth="1000px"
        borderRadius="10px"
      />
      {showCta && (
        <SectionWrapper>
          <CallToAction
            text={CtaText}
            link={CtaLink}
          />
        </SectionWrapper>
      )}
    </Container>
  </GraySection>
);

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
HomeSection.defaultProps = {
  showCta: true,
  CtaLink: constants.TRIAL.LINK_URL,
  CtaText: constants.TRIAL.LINK_TEXT,
};
HomeSection.propTypes = {
  showCta: PropTypes.bool,
  CtaLink: PropTypes.string,
  CtaText: PropTypes.string,
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default HomeSection;
