/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import { Link } from 'gatsby';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Components
import Container from '../../components/Container';
import Image from '../../components/Image';
import {
  BodyTextBig,
  SectionTitle,
  WhiteSection,
  SectionLabel,
  DotWrap,
} from '../../components/Global';
import FullWidthDots from '../../components/Footer/full-width-dots.svg';
import constants from '../../constants';

const ReportsSection = () => (
  <WhiteSection
    id={constants.DOM_ID.REPORTS}
    className="centered-text"
  >
    <DotWrap right="calc(50vw - 2000px)" top="150px">
      <div className="full-width-dots">
        <img src={FullWidthDots} alt="hero dots" />
      </div>
    </DotWrap>
    <Container>
      <SectionLabel>Search and Reporting</SectionLabel>
      <SectionTitle mb="15px">
        Team wide visibility
      </SectionTitle>
      <BodyTextBig maxWidth="615px">
        Create customized reports to view what is important to you.
      </BodyTextBig>
      <BodyTextBig maxWidth="615px">
        With over 50 different search combinations,
        you can gather data, track project progress,
        monitor teamwork and reallocate resources quickly and easily.
      </BodyTextBig>
      <BodyTextBig mb="34px">
        <Link to="/help/articles/61000165265/">
          Learn more about Reports
        </Link>
      </BodyTextBig>
      <Image
        name="product_search.png"
        alt="product search"
        maxWidth="924px"
        borderRadius="10px"
      />
    </Container>
  </WhiteSection>
);

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
ReportsSection.propTypes = {
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default ReportsSection;
