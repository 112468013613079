/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import { Link } from 'gatsby';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
import Container from '../../components/Container';
import Image from '../../components/Image';
import {
  BodyTextBig,
  FlexWrap,
  TextWrap,
  ImageWrap,
  SectionTitle,
  GraySection,
  SectionLabel,
} from '../../components/Global';
import constants from '../../constants';

const CalendarSection = () => (
  <GraySection
    id={constants.DOM_ID.CALENDAR}
    textAlign="left"
  >
    <Container>
      <FlexWrap direction="column-reverse">
        <ImageWrap>
          <Image name="product_deadline.png" alt="product deadline" />
        </ImageWrap>
        <TextWrap width="45%" className="mobile-text-center">
          <SectionLabel mt="40px">Calendar</SectionLabel>
          <SectionTitle mb="16px">
            Never miss a deadline
          </SectionTitle>
          <BodyTextBig mb="16px">
            Connect your work calendar to Workast and see all your tasks
            in your calendar with our 2 way sync.
          </BodyTextBig>
          <BodyTextBig>
            <Link to="/help/articles/61000165231/">Learn more about Calendar</Link>
          </BodyTextBig>
        </TextWrap>
      </FlexWrap>
    </Container>
  </GraySection>
);

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
CalendarSection.propTypes = {
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default CalendarSection;
